export async function paginate (api, req) {
  const data = []
  const limit = req.params.limit
  let page = 1
  let offset = 0

  while (true) {
    offset = (page * limit) - limit
    req.params.offset = offset

    const res = await api(req)
    if (!res.data || res.data.length === 0) {
      break
    }

    data.push(...res.data)

    if (res.data.length < limit) break
    page++
  }
  return { data }
}
