<template>
  <v-container fluid class="ma-0 pa-0">
    <v-toolbar flat color="toolbar">
      <v-breadcrumbs large :items="breadcrumbs">
        <template #item="{ item }">
          <v-breadcrumbs-item
            :href="item.href"
            :disabled="item.disabled">
            <span :class="!item.disabled ? 'primary--text' : ''">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-divider vertical inset class="mx-4" />
      <v-row align="center" dense>
        <v-col align="start">
          <span class="text-subtitle-1">
            <span class="font-weight-medium">
              Ad Dates:
            </span>
            <span style="margin-left: 6px">
              {{ formatDateDisplay(adPlanner.ad_start_date) }} - {{ formatDateDisplay(adPlanner.ad_end_date) }}
            </span>
          </span>
        </v-col>
        <v-col xl="5">
          <SearchFilters :departments="departments" :pages="pages" v-model="searchParams"/>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items.sync="items"
      :item-class="rowHighlight"
      :loading="loading"
      ref="my-table"
      sort-by="['page_name', 'position_name']"
      dense
      fixed-header
      hide-default-footer
      loading-text="Loading... Please Wait"
      no-data-text="No Retail Ad Items to Display"
      no-results-text="No Matches Found"
      item-key="ad_plan_id"
      :expanded.sync="expanded"
      @click:row="highlighted=$event">

      <!-- <template #[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length" class="primary white--text pl-4" style="border: 1px solid grey !important;">
          <v-btn color="white" tabindex="-1" dense @click.stop="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ group }}
        </td>
      </template> -->

      <!-- formatting the headers -->
      <template #[`item.components`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn v-on="on"
              v-if="item.is_shipper"
              icon color="primary" @click.stop="showComponentsModal(item)">
              <v-icon>mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>Components</span>
        </v-tooltip>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          @click="highlighted=item"
          :colspan="headers.length"
          class="expandedRow"
          :class="item.item_id === highlighted.item_id ? 'primary white--text' : ''"
          style="cursor:pointer">
        <v-row v-if="items.length && showDeliveryDates" dense>
          <v-col cols="1" style="height:50px;" class="pt-1 mb-3"
            v-for="order in item.item_orders" :key="order.item_id">
            <v-text-field
              :disabled="item.is_dsd"
              :readonly="order.processed || orderingClosed(item, order)"
              hide-details
              outlined
              solo
              :background-color="order.processed || orderingClosed(item, order) ? 'grey lighten-3' : 'white'"
              :placeholder="orderingClosed(item, order) ? 'Closed' : 'Quantity'"
              class="mt-2"
              dense
              type="number"
              :color="order.processed || orderingClosed(item, order) ? 'grey darken-1' : 'primary'"
              :label="moment(order.delivery_date).format('ddd_MM/DD')"
              @focus="activeInput=order"
              @change="setOrder(item, order, $event)"
              :value="order.quantity > 0 ? order.quantity : null"
              min="1">
              <template #append>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon v-on="on" :color="order.processed ? 'green' : orderingClosed(item, order) ? 'red' : ''">
                      {{order.processed ? 'mdi-checkbox-marked-circle-outline' : ''}}
                    </v-icon>
                  </template>
                  <span>{{order.processed ? 'Order Processed' : orderingClosed(item, order) ? 'Ordering Closed' : ''}}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1" class="ml-4 mt-5" align="center">
            <v-badge
              class="font-weight-medium"
              color="green"
              :value="item.total_ordered >= 1"
              :content="item.total_ordered">
              <v-icon :color="item.item_id === highlighted.item_id ? 'white' : ''">mdi-cart-outline</v-icon>
            </v-badge>
          </v-col>
          <v-col cols="2" class="ma-2">
            <span>
              <b class="pr-2">AMAP Dates:</b>
              {{
                item.amap_start_date && item.amap_end_date ?
                  `${formatDateDisplay(item.amap_start_date)} - ${formatDateDisplay(item.amap_end_date)}` :
                    '---'
              }}
            </span>
            <br>
            <span>
              <b class="pr-2">TPR Dates:</b>
              {{
                item.tpr_start_date && item.tpr_end_date ?
                `${formatDateDisplay(item.tpr_start_date)} - ${formatDateDisplay(item.tpr_end_date)}` :
                  '---'
              }}
            </span>
          </v-col>
          <v-col cols="2" align="center">
            <v-btn
              height="38"
              v-if="item.is_shipper"
              text
              :color="item.item_id === highlighted.item_id ? 'white' : 'primary'" @click.stop="showComponentsModal(item)"
              class="text-capitalize"
              style="margin-top:10px">
              <v-icon left>mdi-magnify</v-icon>
              Components
            </v-btn>
          </v-col>
        </v-row>
        </td>
      </template>

      <template #[`item.last_90_days_shipped_qty`]="{ item }">
        <span class="noWrap">{{ item.last_90_days_shipped_qty ? item.last_90_days_shipped_qty : '---' }}</span>
      </template>

      <template #[`item.tpr_dates`]="{ item }">
        <span class="noWrap">
          {{
            item.tpr_start_date && item.tpr_end_date
            ?`${formatDateDisplay(item.tpr_start_date)} - ${formatDateDisplay(item.tpr_end_date)}`
            : '---'
          }}
          </span>
      </template>

      <template #[`item.department`]="{ item }">
        <span class="noWrap">{{ item.department }}</span>
      </template>

      <template #[`item.description`]="{ item }">
        <span class="noWrap">{{ item.description }}</span>
      </template>

      <template #[`item.upc`]="{ item }">
        <span class="noWrap">{{ item.upc ? item.upc : '---' }}</span>
      </template>

      <template #[`item.case_cost`]="{ item }">
        <span class="noWrap">
          ${{item.case_cost | format}}
        </span>
      </template>

      <template #[`item.amap`]="{ item }">
        <span class="noWrap">
          ${{ item.amap | format}}
        </span>
      </template>

      <template #[`item.early_buy_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.early_buy_billback | format}}
        </span>
      </template>

      <template #[`item.ad_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.ad_billback | format}}
        </span>
      </template>

      <template #[`item.tpr_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.tpr_billback | format}}
        </span>
      </template>

      <template #[`item.edlc_billback`]="{ item }">
        <span class="noWrap">
          ${{ item.edlc_billback | format }}
        </span>
      </template>

      <template #[`item.ad_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.ad_scan | format}}
        </span>
      </template>

      <template #[`item.tpr_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.tpr_scan | format}}
        </span>
      </template>

      <template #[`item.edlc_scan`]="{ item }">
        <span class="noWrap">
          ${{ item.edlc_scan | format }}
        </span>
      </template>

      <template #[`item.net_unit_cost`]="{ item }">
        <span class="noWrap">
          ${{ item.net_unit_cost | format}}
        </span>
      </template>

      <template #[`item.unit_cost`]="{ item }">
        <span class="noWrap">
          ${{ item.unit_cost | format}}
        </span>
      </template>

      <template #[`item.ad_retail`]="{ item }">
        <span class="noWrap">
          {{item.ad_retail_count}}/${{item.ad_retail | format}}
        </span>
      </template>

      <template #[`item.ad_gross_profit_percent`]="{ item }">
        <span class="noWrap">
          {{ item.ad_gross_profit_percent | format}}%
        </span>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="toolbar">
          <v-spacer></v-spacer>
          <v-col cols="2" style="max-width: 150px">
            <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col>
          <v-btn icon large @click="previous" :disabled="disablePrevious" class="mx-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <small>Page {{ page }}</small>
          <v-btn icon large @click="next" :disabled="disableNext" class="mx-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog :value.sync="dialog">
      <v-card>
        <v-card-title>
          <v-icon size="35" class="red--text mr-3">mdi-alert-octagon</v-icon>
          <span class="font-weight-light">Unable to Proceed</span>
        </v-card-title>
        <v-card-text>
          No order delivery days are assigned to store.
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text class="text-capitalize font-weight-medium" :to="{ path: '/retail-manager' }">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ComponentItems
      v-if="adPlanner && showComponents"
      v-model="showComponents"
      :ad_id="adPlanner.ad_id"
      :selectedItem="selectedItem"
      @close="showComponents = false">
    </ComponentItems>

  </v-container>
</template>

<script>
// api
import AdRetails from "@/axios/ad-retails"
import Attributes from "@/axios/attributes"
import ItemOrder from '@/axios/item_order'
// mixins
import { displayAlert } from "@/mixins/alert"
import { fullwidth } from "@/mixins/fullwidth"
import { paging } from "@/mixins/paging"
import { displayHelpers } from "@/mixins/displayHelpers"
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// third-party
import _ from 'lodash'
import moment from 'moment'
// components

const SearchFilters = () => import('@/components/SearchFilters.vue')
const ComponentItems = () => import('@/components/ComponentItems.vue')

export default {
  name: "RetailAdItems",

  components: { SearchFilters, ComponentItems },

  mixins: [displayAlert, fullwidth, displayHelpers, paging, userAccess, utils],

  data() {
    return {
      adPlanId: 0,
      adPlanner: {},
      adPlannerParty: {},
      departments: [],
      dialog: false,
      expanded: [],
      filters: [],
      highlighted: {},
      items: [],
      loading: true,
      orderItem: null,
      orders: [],
      pages: [],
      searchParams: {},
      selected: [],
      selectedItem: {},
      shipperMod: {},
      showComponents: false,
      filterByShipperMods: false,
      showDeliveryDates: true,
      store_id: null,
      store_name: null,
      storeDeliveryDays: [],
      tableHeight: "",
      processedOrders: []
    }
  },

  watch: {
    adPlanner: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem("ad_planner", JSON.stringify(this.adPlanner))
        }
      },
      deep: true
    },

    adPlannerParty: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem("ad_planner_party", JSON.stringify(this.adPlannerParty))
        }
      },
      deep: true
    },

    itemFilters: {
      handler: _.debounce(function (newValue) {
        this.items = []
        this.loading = true
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          } else {
            this.getOrderGridData()
          }
        }
      }, 500),
      deep: true
    }
  },

  async created () {
    this.nonTableHeight = 224
    // Route Params
    const { adPlanner, party, store_id, store_name } = this.$route.params
    this.adPlanner = adPlanner || JSON.parse(sessionStorage.getItem("ad_planner"))
    this.adPlannerParty = party || JSON.parse(sessionStorage.getItem("ad_planner_party"))
    this.store_id = store_id || sessionStorage.getItem("store_id")
    this.store_name = store_name || sessionStorage.getItem("store_name")
    this.getOrderGridData()
    this.tableChanged = this.tableChange(this.getOrderGridData)
    // this.getStoreDeliveryDays()
    this.getDepartments()
    this.getPages()
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.adPlannerParty.name,
          exact: true,
          href: '/retail-manager'
        },
        {
          text: this.adPlanner.ad_name,
          exact: true,
          disabled: true
        },
        {
          text: this.store_name,
          exact: true,
          disabled: true
        }
      ]
    },

    storeDeliveryDates () {
      const startDate = moment(this.adPlanner.ad_start_date).subtract(1, 'weeks')
      const endDate = moment(this.adPlanner.ad_start_date).add(6, 'days')
      const now = startDate.clone()
      const days = []
      while (now.isSameOrBefore(endDate)) {
        days.push({ date: now.format('YYYY-MM-DD'), quantity: '' })
        now.add(1, 'days')
      }
      return days.filter(day => this.storeDeliveryDays.includes(moment(day.date).day()))
    },

    totalItems () {
      return this.items.length
    },

    disablePrevious () {
      return (this.page - 1) * this.pageSize === 0
    },

    disableNext () {
      return this.totalItems < this.pageSize
    },

    headers () {
      return [
        { text: 'Department', sortable: true, filterable: true, class: 'accent white--text', value: 'department' },
        { text: 'Item No', sortable: true, filterable: true, class: 'accent white--text', value: 'item_id' },
        { text: 'UPC', sortable: true, filterable: true, class: 'accent white--text', value: 'upc' },
        { text: 'Description', sortable: true, filterable: true, class: 'accent white--text', value: 'item_desc' },
        { text: 'Pack', sortable: true, filterable: true, class: 'accent white--text', value: 'qty_per_case' },
        { text: 'Size', sortable: true, filterable: true, class: 'accent white--text', value: 'pack_size' },
        { text: 'Case Cost', width: '105', sortable: true, filterable: true, class: 'accent white--text', value: 'case_cost' },
        { text: 'Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'unit_cost' },
        { text: 'AMAP', sortable: true, filterable: true, class: 'accent white--text', value: 'amap' },
        { text: 'EBA', sortable: true, filterable: true, class: 'accent white--text', value: 'early_buy_billback' },
        { text: 'Ad BB', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_billback' },
        { text: 'TPR BB', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_billback' },
        { text: 'EDLC BB', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_billback' },
        { text: 'Ad Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_scan' },
        { text: 'TPR Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_scan' },
        { text: 'EDLC Scan', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_scan' },
        { text: 'Net Unit Cost', sortable: true, filterable: true, class: 'accent white--text', value: 'net_unit_cost' },
        { text: 'Ad SRP', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_retail' },
        { text: 'Ad Gross Profit %', sortable: true, filterable: true, class: 'accent white--text', value: 'ad_gross_profit_percent' },
        { text: 'Avg. Movement', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty' }
        // { text: 'Components', align: 'center', filterable: false, sortable: false, class: 'accent white--text', value: 'components' }
      ]
    },

    orderingInfo() {
      const adStartDate = moment(this.adPlanner.ad_start_date)
      const currentDate = moment().format(this.$dateConfig.date_format)
      let orderInfo = {
        currentDate: currentDate,
        lastOrderDate: moment(adStartDate).subtract(4, 'weeks').format(this.$dateConfig.date_format),
        lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').format(this.$dateConfig.date_format)
      }
      if (this.tenant === "pricechopper") {
        orderInfo = {
          ...orderInfo,
          lastOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format(this.$dateConfig.date_format),
          lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format(this.$dateConfig.date_format)
        }
      }
      return orderInfo
    },

    itemFilters () {
      return { ...this.searchParams }
    }
  },

  methods: {
    rowHighlight(item) {
      return item.item_id === this.highlighted.item_id ? 'primary white--text' : ''
    },

    showComponentsModal(item) {
      this.selectedItem = item
      this.showComponents = true
    },

    orderingClosed(item) {
      const isShipperItem = item.is_shipper
      const { currentDate, lastOrderDate, lastShipperOrderDate } = this.orderingInfo
      return (isShipperItem && currentDate > lastShipperOrderDate) || (currentDate > lastOrderDate)
    },

    async getStoreDeliveryDays() {
      try {
        const { data } = await Attributes.getStoreDeliveryDays(
          this.store_id,
          100,
          0
        )
        const storeDeliveryDays = data.reduce((acc, key) => {
          if (key.attr.id === "STORE_DELIVERY_DAYS") {
            acc.push(
              moment().day(key.attr_key_value.name).format("dddd").toLowerCase()
            )
          }
          return acc
        }, [])

        if (storeDeliveryDays.length > 0) {
          return storeDeliveryDays
        } else {
          this.dialog = true
        }
      } catch (err) {
        this.handleError(err)
      }
    },

    async getOrderGridData() {
      let items = []
      const storeDeliveryDays = await this.getStoreDeliveryDays()
      const params = this.buildPromoListParams()
      try {
        const { data } = await AdRetails.getOrderGridData(
          params,
          this.store_id,
          this.adPlanner.ad_id,
          storeDeliveryDays,
          this.from,
          this.pageSize
        )
        if (data?.length > 0) {
          items = data.flatMap(item => {
            item.is_dsd = item.item_id.startsWith('D', 0)
            return this.getItemTotalOrdered(item)
          })
          this.items = items
          this.expanded = this.items
        } else {
          this.items = []
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },

    buildPromoListParams() {
      let params = {}
      if (Object.entries(this.itemFilters).length > 0) {
        params = { ...this.itemFilters }
      }
      return params
    },

    getItemTotalOrdered(item) {
      const totalOrdered = item.item_orders.reduce((total, order) => total + (order.quantity || 0), 0)
      item.total_ordered = totalOrdered
      return item
    },

    setOrder (item, order, quantity) {
      if (!_.isEmpty(order.order_id) && quantity) {
        if (order.quantity !== quantity) {
          this.updateOrder(item, order, quantity)
        }
      } else if (!_.isEmpty(order.order_id) && quantity === '') {
        this.deleteOrder(order.order_id)
      } else {
        this.createOrder(item, order, quantity)
      }
    },

    buildRequestBody(item, order, quantity) {
      const payload = {
        party_id: this.store_id,
        ad_id: this.adPlanner.ad_id,
        feature_id: item.feature_id,
        item_id: item.item_id,
        quantity: quantity,
        expected_delivery_date: order.delivery_date
      }
      return payload
    },

    async createOrder (item, order, quantity) {
      const requestBody = this.buildRequestBody(item, order, quantity)
      try {
        await ItemOrder.post(requestBody)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async updateOrder (item, order, quantity) {
      const requestBody = this.buildRequestBody(item, order, quantity)
      try {
        await ItemOrder.put(order.order_id, requestBody)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async deleteOrder(orderId) {
      try {
        await ItemOrder.delete(orderId)
      } catch (err) {
        this.handleError(err)
      } finally {
        await this.getOrderGridData()
      }
    },

    async getPages() {
      try {
        const { data } = await AdRetails.getPages(this.adPlanner.ad_id)
        if (data.length > 0) {
          this.pages = data
        }
      } catch (err) {
        this.handleError(err)
      }
    },

    async getDepartments() {
      try {
        const { data } = await AdRetails.getDepartments(this.adPlanner.ad_id)
        if (data.length > 0) {
          this.departments = data
        }
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>
<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white;
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

.text-start {
  border-top: 1px solid #9e9e9e !important;
  border-bottom: none !important;
  /* border-top: 2px solid #385f73 !important; */
}

.noWrap {
  white-space: nowrap;
}

.active {
  box-shadow: 0px 2px 10px 3px rgba(120, 120, 120, 0.75);
  background: #385f73;
  color: white !important;
}

.filtered {
  background: #ffc107 !important;
  color: white !important;
}
</style>
