import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class ItemOrder {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_PROMO_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.itemOrder = base
  }

  async getItemOrders (party_id, item_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: '/item_orders',
        method: 'GET',
        params: { party_id, item_id, offset, limit }
      })
    }
    return this.itemOrder({
      url: '/item_orders',
      method: 'GET',
      params: { party_id, item_id, offset, limit }
    })
  }

  async getProcessedItemOrders(party_id, feature_id, item_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/processed_order/${party_id}/${feature_id}`,
        method: 'GET',
        params: { item_id, limit, offset }
      })
    }
    return this.itemOrder({
      url: `/processed_order/${party_id}/${feature_id}`,
      method: 'GET',
      params: { item_id, limit, offset }
    })
  }

  async getAdItemOrders (ad_id, party_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/item_orders/${ad_id}/${party_id}`,
        method: 'GET',
        params: { offset, limit }
      })
    }
    return this.itemOrder({
      url: `/item_orders/${ad_id}/${party_id}`,
      method: 'GET',
      params: { offset, limit }
    })
  }

  async getAdProcessedItemOrders (ad_id, party_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/processed_orders/${ad_id}/${party_id}`,
        method: 'GET',
        params: { offset, limit }
      })
    }
    return this.itemOrder({
      url: `/processed_orders/${ad_id}/${party_id}`,
      method: 'GET',
      params: { offset, limit }
    })
  }

  async post (orderData) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: '/item_order/',
      method: 'POST',
      data: orderData
    })
  }

  async put (orderId, orderData) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/item_order/${orderId}`,
      method: 'PUT',
      data: orderData
    })
  }

  async delete (orderId) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/item_order/${orderId}`,
      method: 'DELETE'
    })
  }
}

export default new ItemOrder()
