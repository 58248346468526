var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"toolbar"}},[_c('v-breadcrumbs',{attrs:{"large":"","items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href,"disabled":item.disabled}},[_c('span',{class:!item.disabled ? 'primary--text' : ''},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}])}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"align":"start"}},[_c('span',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" Ad Dates: ")]),_c('span',{staticStyle:{"margin-left":"6px"}},[_vm._v(" "+_vm._s(_vm.formatDateDisplay(_vm.adPlanner.ad_start_date))+" - "+_vm._s(_vm.formatDateDisplay(_vm.adPlanner.ad_end_date))+" ")])])]),_c('v-col',{attrs:{"xl":"5"}},[_c('SearchFilters',{attrs:{"departments":_vm.departments,"pages":_vm.pages},model:{value:(_vm.searchParams),callback:function ($$v) {_vm.searchParams=$$v},expression:"searchParams"}})],1)],1)],1),_c('v-data-table',{ref:"my-table",attrs:{"headers":_vm.headers,"height":_vm.tableSize,"items-per-page":_vm.pageSize,"items":_vm.items,"item-class":_vm.rowHighlight,"loading":_vm.loading,"sort-by":"['page_name', 'position_name']","dense":"","fixed-header":"","hide-default-footer":"","loading-text":"Loading... Please Wait","no-data-text":"No Retail Ad Items to Display","no-results-text":"No Matches Found","item-key":"ad_plan_id","expanded":_vm.expanded},on:{"update:items":function($event){_vm.items=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":function($event){_vm.highlighted=$event}},scopedSlots:_vm._u([{key:"item.components",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.is_shipper)?_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.showComponentsModal(item)}}},on),[_c('v-icon',[_vm._v("mdi-view-list")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Components")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandedRow",class:item.item_id === _vm.highlighted.item_id ? 'primary white--text' : '',staticStyle:{"cursor":"pointer"},attrs:{"colspan":headers.length},on:{"click":function($event){_vm.highlighted=item}}},[(_vm.items.length && _vm.showDeliveryDates)?_c('v-row',{attrs:{"dense":""}},[_vm._l((item.item_orders),function(order){return _c('v-col',{key:order.item_id,staticClass:"pt-1 mb-3",staticStyle:{"height":"50px"},attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"disabled":item.is_dsd,"readonly":order.processed || _vm.orderingClosed(item, order),"hide-details":"","outlined":"","solo":"","background-color":order.processed || _vm.orderingClosed(item, order) ? 'grey lighten-3' : 'white',"placeholder":_vm.orderingClosed(item, order) ? 'Closed' : 'Quantity',"dense":"","type":"number","color":order.processed || _vm.orderingClosed(item, order) ? 'grey darken-1' : 'primary',"label":_vm.moment(order.delivery_date).format('ddd_MM/DD'),"value":order.quantity > 0 ? order.quantity : null,"min":"1"},on:{"focus":function($event){_vm.activeInput=order},"change":function($event){return _vm.setOrder(item, order, $event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":order.processed ? 'green' : _vm.orderingClosed(item, order) ? 'red' : ''}},on),[_vm._v(" "+_vm._s(order.processed ? 'mdi-checkbox-marked-circle-outline' : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(order.processed ? 'Order Processed' : _vm.orderingClosed(item, order) ? 'Ordering Closed' : ''))])])]},proxy:true}],null,true)})],1)}),_c('v-col',{staticClass:"ml-4 mt-5",attrs:{"cols":"1","align":"center"}},[_c('v-badge',{staticClass:"font-weight-medium",attrs:{"color":"green","value":item.total_ordered >= 1,"content":item.total_ordered}},[_c('v-icon',{attrs:{"color":item.item_id === _vm.highlighted.item_id ? 'white' : ''}},[_vm._v("mdi-cart-outline")])],1)],1),_c('v-col',{staticClass:"ma-2",attrs:{"cols":"2"}},[_c('span',[_c('b',{staticClass:"pr-2"},[_vm._v("AMAP Dates:")]),_vm._v(" "+_vm._s(item.amap_start_date && item.amap_end_date ? ((_vm.formatDateDisplay(item.amap_start_date)) + " - " + (_vm.formatDateDisplay(item.amap_end_date))) : '---')+" ")]),_c('br'),_c('span',[_c('b',{staticClass:"pr-2"},[_vm._v("TPR Dates:")]),_vm._v(" "+_vm._s(item.tpr_start_date && item.tpr_end_date ? ((_vm.formatDateDisplay(item.tpr_start_date)) + " - " + (_vm.formatDateDisplay(item.tpr_end_date))) : '---')+" ")])]),_c('v-col',{attrs:{"cols":"2","align":"center"}},[(item.is_shipper)?_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"margin-top":"10px"},attrs:{"height":"38","text":"","color":item.item_id === _vm.highlighted.item_id ? 'white' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.showComponentsModal(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Components ")],1):_vm._e()],1)],2):_vm._e()],1)]}},{key:"item.last_90_days_shipped_qty",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(_vm._s(item.last_90_days_shipped_qty ? item.last_90_days_shipped_qty : '---'))])]}},{key:"item.tpr_dates",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" "+_vm._s(item.tpr_start_date && item.tpr_end_date ?((_vm.formatDateDisplay(item.tpr_start_date)) + " - " + (_vm.formatDateDisplay(item.tpr_end_date))) : '---')+" ")])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(_vm._s(item.department))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(_vm._s(item.description))])]}},{key:"item.upc",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(_vm._s(item.upc ? item.upc : '---'))])]}},{key:"item.case_cost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.case_cost))+" ")])]}},{key:"item.amap",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.amap))+" ")])]}},{key:"item.early_buy_billback",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.early_buy_billback))+" ")])]}},{key:"item.ad_billback",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.ad_billback))+" ")])]}},{key:"item.tpr_billback",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.tpr_billback))+" ")])]}},{key:"item.edlc_billback",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.edlc_billback))+" ")])]}},{key:"item.ad_scan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.ad_scan))+" ")])]}},{key:"item.tpr_scan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.tpr_scan))+" ")])]}},{key:"item.edlc_scan",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.edlc_scan))+" ")])]}},{key:"item.net_unit_cost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.net_unit_cost))+" ")])]}},{key:"item.unit_cost",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" $"+_vm._s(_vm._f("format")(item.unit_cost))+" ")])]}},{key:"item.ad_retail",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" "+_vm._s(item.ad_retail_count)+"/$"+_vm._s(_vm._f("format")(item.ad_retail))+" ")])]}},{key:"item.ad_gross_profit_percent",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"noWrap"},[_vm._v(" "+_vm._s(_vm._f("format")(item.ad_gross_profit_percent))+"% ")])]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-toolbar',{attrs:{"flat":"","color":"toolbar"}},[_c('v-spacer'),_c('v-col',{staticStyle:{"max-width":"150px"},attrs:{"cols":"2"}},[_c('v-select',{staticClass:"mt-8",attrs:{"dense":"","items":_vm.pageSizes,"label":"Items Per Page","menu-props":{ top: true, offsetY: true, maxHeight: 500 }},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disablePrevious},on:{"click":_vm.previous}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('small',[_vm._v("Page "+_vm._s(_vm.page))]),_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","large":"","disabled":_vm.disableNext},on:{"click":_vm.next}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"value":_vm.dialog},on:{"update:value":function($event){_vm.dialog=$event}}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"red--text mr-3",attrs:{"size":"35"}},[_vm._v("mdi-alert-octagon")]),_c('span',{staticClass:"font-weight-light"},[_vm._v("Unable to Proceed")])],1),_c('v-card-text',[_vm._v(" No order delivery days are assigned to store. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize font-weight-medium",attrs:{"text":"","to":{ path: '/retail-manager' }}},[_vm._v("Close")])],1)],1)],1),(_vm.adPlanner && _vm.showComponents)?_c('ComponentItems',{attrs:{"ad_id":_vm.adPlanner.ad_id,"selectedItem":_vm.selectedItem},on:{"close":function($event){_vm.showComponents = false}},model:{value:(_vm.showComponents),callback:function ($$v) {_vm.showComponents=$$v},expression:"showComponents"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }